import React, { useEffect, useRef, useState } from 'react';
import ReactHtmlTableToExcel from 'react-html-table-to-excel';
import { useQuery } from 'react-query';
import { useReactToPrint } from 'react-to-print';
import { toast } from 'react-toastify';
import useFetchData from '../../../../hooks/useFetchData';
import Loader from '../../../Shared/Loader';

const CustomerDueReport = () => {
    const [customers, setCustomers] = useState([]);
    const [searchResult, setSearchResult] = useState([]);
    const [isSearching, setIsSearching] = useState(false);
    const [isLoading, setIsLoading] = useState(true);
    const [customerText, setCustomerText] = useState([]);
    const componentRef = useRef()
    const { items: sms } = useFetchData('https://servers.clearsoftwares.net/myac/getsms');

    console.log(sms);


    const generatePrint = useReactToPrint({
        content: () => componentRef.current,
        documentTitle: `Cash Statement`,

    });

    useEffect(() => {
        const url = `https://servers.clearsoftwares.net/myac/donors`
        fetch(url)
            .then(res => res.json())
            .then(data => {
                // const filtered = data?.customerDues.filter(due=>due.balance!==0)
                setCustomers(data);
                // console.log(data?.customerDues);
                setIsLoading(false)
            })
    }, []);


    if (isLoading) {
        return <Loader></Loader>
    }

    const handleSearchResult = (event) => {
        const searchText = event.target.value.toLowerCase();
        const match = customers.filter(customer => customer.donorName.includes(searchText));
        setSearchResult(match);
        setIsSearching(true);
    }


    const handleCallClick = (customer) => {
        if (customer?.donorPhone) {
            window.location.href = `tel:${customer.donorPhone}`;
        }
    }

    const totalBalance = isSearching ?
        searchResult.reduce((total, currentValue) => total +
            currentValue.months.reduce((monthSum, month) => monthSum + parseFloat(month.monthly), 0), 0) :
        customers.reduce((total, currentValue) => total +
            currentValue.months.reduce((monthSum, month) => monthSum + parseFloat(month.monthly), 0), 0);

    const handleSendSMS = (customer) => {
        if (sms[0]?.smsAmount > 3) {
            const token = `92310756091678413369ac9dc34ff7599524a6ea84a77094beb1`;
            const number = customer.donorPhone;
            const msg = `প্রিয় ${customer.donorName}, দয়া করে ${customer?.months.map((month, monthIndex) => `${month.month}${monthIndex !== customer?.months.length - 1 ? ', ' : ''}`).join('')} মাসিক দান বাবদ বকেয়া ${customer?.months.reduce((sum, month) => sum + parseFloat(month.monthly), 0)} টাকা দ্রুত পরিশোধ করুন, ফকিরবাড়ি জামে মসজিদ।`;
            const message = encodeURI(msg)
            const url = `https://api.greenweb.com.bd/api.php?token=${token}&to=${number}&message=${message}`

            fetch(url)
                .then(res => res.json())
                .then(data => {
                    toast.success(`${customer.donorName} কে SMS পাঠানো হয়েছে`)
                })

            fetch(`https://servers.clearsoftwares.net/myac/getsms/${sms[0]?._id}`, {
                method: 'PUT',
                headers: {
                    'content-type': 'application/json'
                },
                body: JSON.stringify({ smsAmount: sms[0]?.smsAmount - 2 })
            })
                .then(res => res.json())
                .then(data => {
                    console.log(data);
                })
        }
    }



    return (
        <div className='mx-2'>
            <div className='text-right mt-12'>
                <ReactHtmlTableToExcel
                    id="test-table-xls-button"
                    className="btn btn-error text-white btn-xs mt-2 lg:mt-0"
                    table="table-to-xls"
                    filename={`Balance Sheet`}
                    sheet={`balance-sheet`}
                    buttonText="Export to exel" />
                <button className='btn btn-success btn-xs ml-2 text-white' onClick={generatePrint}>Print</button>
            </div>
            <div className='flex lg:justify-end justify-center items-center mt-4'>
                <div class="form-control w-60">
                    <input onChange={handleSearchResult} type="text" placeholder="Search Donor" class="input text-xs input-bordered bg-white input-error w-full max-w-xs" />
                </div>
            </div>
            <div ref={componentRef} class="overflow-x-auto">
                <table id='table-to-xls' class="table w-1/2 mx-auto text-xs mb-20">
                    <caption className='text-center text-lg mb-2 mt-16'>Donor Due Report</caption>
                    <thead>
                        <tr className='text-center'>
                            <th className='bg-red-600 text-white normal-case'>SL</th>
                            <th className='bg-red-600 text-white normal-case'>Donor Code </th>
                            <th className='bg-red-600 text-white normal-case'>Donor Name</th>
                            <th className='bg-red-600 text-white normal-case'>Address</th>
                            <th className='bg-red-600 text-white normal-case'>Phone</th>
                            <th className='bg-red-600 text-white normal-case'>Monthly</th>
                            <th className='bg-red-600 text-white normal-case'>Due Months</th>
                            <th className='bg-red-600 text-white normal-case'>Due Amount</th>
                            <th className='bg-red-600 text-white normal-case'>Action</th>
                        </tr>
                    </thead>
                    <tbody>
                        {
                            isSearching ? searchResult.map((customer, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{customer?.donorCode}</td>
                                <td>{customer?.donorName}</td>
                                <td>{customer?.donorAddress}</td>
                                <td>{customer?.donorPhone && (
                                    <a className='text-red-600' href={`tel:${customer.donorPhone}`} onClick={() => handleCallClick(customer)}>
                                        {customer.donorPhone}
                                    </a>
                                )}</td>
                                <td className='text-blue-800'>{customer?.donorMonthly}</td>
                                <td className='text-green-600'>
                                    {customer?.months.map((month, monthIndex) => (
                                        <span key={monthIndex}>{month.month}{monthIndex !== customer?.months.length - 1 && ', '}</span>
                                    ))}
                                </td>
                                <td className='text-green-600'>
                                    {customer?.months.reduce((sum, month) => sum + parseFloat(month.monthly), 0)}
                                </td>
                                <td><button onClick={() => handleSendSMS(customer)} className='btn btn-xs btn-success text-white'>Send SMS</button></td>
                            </tr>) : customers.map((customer, index) => <tr className='text-center'>
                                <td>{index + 1}</td>
                                <td>{customer?.donorCode}</td>
                                <td>{customer?.donorName}</td>
                                <td>{customer?.donorAddress}</td>
                                <td >{customer?.donorPhone && (
                                    <a className='text-red-600' href={`tel:${customer.donorPhone}`} onClick={() => handleCallClick(customer)}>
                                        {customer.donorPhone}
                                    </a>
                                )}</td>
                                <td className='text-blue-800'>{customer?.donorMonthly}</td>
                                <td className='text-green-600'>
                                    {customer?.months.map((month, monthIndex) => (
                                        <span key={monthIndex}>{month.month}{monthIndex !== customer?.months.length - 1 && ', '}</span>
                                    ))}
                                </td>
                                <td className='text-green-600'>
                                    {customer?.months.reduce((sum, month) => sum + parseFloat(month.monthly), 0)}
                                </td>
                                <td><button onClick={() => handleSendSMS(customer)} className='btn btn-xs btn-success text-white'>Send SMS</button></td>
                            </tr>)
                        }
                    </tbody>
                    <tfoot>
                        <tr>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'></th>
                            <th className='bg-red-600 text-white normal-case'>Total Dues</th>
                            <th className='bg-red-600 text-white normal-case'>{totalBalance}</th>
                            <th className='bg-red-600 text-white normal-case'></th>
                        </tr>
                    </tfoot>
                </table>
            </div>
        </div>
    );
};

export default CustomerDueReport;