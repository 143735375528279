import logo from '../../assets/images/logo.jpeg'

const BusinessInfo = {
    name: "My Accounts",
    fullName: "My Accounts",
    dealer: "(Accounts Activity of Personal Life)",
    address: "Thakurgaon",
    mobile: "Mobile: 01772545974",
    img: logo,
}

export default BusinessInfo;
